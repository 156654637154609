import { AuthGroupsContextProvider } from "@common/components/AuthGroupsProvider/AuthGroupsProvider";
import { connectApi } from "@common/services/ajax";
import { AuthClient } from "@common/services/AuthClient.service";
import { getEnv } from "@common/services/getEnvApplicationSettings.service";
import { CssReset } from "@design-system/components/CssReset/CssReset";
import { RhFlashProvider } from "@design-system/components/RhFlashProvider/RhFlashProvider";
import { muiTheme } from "@design-system/theme/rhythmMui.theme";
import isPropValid from "@emotion/is-prop-valid";
import { init as fullStoryInit } from "@fullstory/browser";
import { ThemeProvider } from "@mui/material";
import { Security } from "@okta/okta-react";
import { App } from "@ops/App";
import { FeatureFlagClientOpsProvider } from "@ops/components/FeatureFlagClientOpsProvider/FeatureFlagClientOpsProvider";
import { signInPath } from "@ops/routes/routePaths";
import {
  AXIOS_BASE_URL,
  AXIOS_TIMEOUT_MS,
  FullStoryConfig,
  OKTA_CONFIG,
  SENTRY_CONFIG,
} from "@ops/settings/config";
import { store } from "@ops/store/store";
import { isLocal } from "@ops/utils/env.util";
import { generateTranslations } from "@ops/utils/generateTranslations.util";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Provider as StoreProvider } from "react-redux";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigate,
  useNavigationType,
} from "react-router-dom";
import { StyleSheetManager } from "styled-components";

const queryClient = new QueryClient();
const authClient = AuthClient.getInstance(OKTA_CONFIG);

// This implements the default behavior from styled-components v5 until we can fix all existing props
function shouldForwardProp(propName: string, target: unknown) {
  if (typeof target === "string") {
    if (isLocal() && !isPropValid(propName)) {
      // eslint-disable-next-line no-console
      console.log({ propName, target });
    }
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

// eslint-disable-next-line react-refresh/only-export-components
const AppWithProviders = () => {
  const navigate = useNavigate();
  const restoreOriginalUri = authClient.getRestoreOriginalUri(navigate);

  return (
    <RhFlashProvider>
      <Security
        oktaAuth={authClient.authClient}
        onAuthRequired={() => {
          return new Promise(() => {
            navigate(signInPath());
          });
        }}
        restoreOriginalUri={restoreOriginalUri}
      >
        <AuthGroupsContextProvider>
          <FeatureFlagClientOpsProvider>
            <QueryClientProvider client={queryClient}>
              <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                <App />
              </StyleSheetManager>
            </QueryClientProvider>
          </FeatureFlagClientOpsProvider>
        </AuthGroupsContextProvider>
      </Security>
    </RhFlashProvider>
  );
};

const bootstrapApp = () => {
  connectApi({
    baseURL: AXIOS_BASE_URL,
    timeout: AXIOS_TIMEOUT_MS,
  });

  if (FullStoryConfig) {
    fullStoryInit(FullStoryConfig);
  }

  if (SENTRY_CONFIG.dsn) {
    Sentry.init({
      dsn: SENTRY_CONFIG.dsn,
      environment: getEnv(),
      integrations: [
        Sentry.rewriteFramesIntegration(),
        Sentry.browserTracingIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
          createRoutesFromChildren,
          matchRoutes,
          useEffect,
          useLocation,
          useNavigationType,
        }),
      ],
      release: SENTRY_CONFIG.release,
      tracePropagationTargets: ["localhost", "*.gotrhythm.com"],
      tracesSampleRate: SENTRY_CONFIG.tracesSampleRate,
    });
  }

  // Even if we don't need translations for ops, form validators do need to them to translate form errors.
  // For ops, we just load the common translations so the validator can return the english sentence
  generateTranslations();

  const container = document.getElementById("root");

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);

  root.render(
    <React.StrictMode>
      <CssReset />
      <Sentry.ErrorBoundary>
        <ThemeProvider theme={muiTheme}>
          <StoreProvider store={store}>
            <BrowserRouter>
              <AppWithProviders />
            </BrowserRouter>
          </StoreProvider>
        </ThemeProvider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>
  );
};

bootstrapApp();
